import './App.css';
import Voice from './components/Voice';
import Recorder from './components/Recorder';
function App() {
  return (
    <div className="App">
        <Voice/>
        {/* <Recorder/> */}
    </div>
  );
}

export default App;
