import React, { useState, useRef } from "react";

const Voice = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isGifAnimated, setIsGifAnimated] = useState(false);
  const [isAudioAvailable, setIsAudioAvailable] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const silenceStartRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const threshold = 0.5; 
  const silenceTimeout = 5000; 

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (!stream) {
        throw new Error("No stream received");
      }

      mediaRecorderRef.current = new MediaRecorder(stream);
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);
      audioContextRef.current = audioContext;
      analyserRef.current = analyser;
      dataArrayRef.current = new Uint8Array(analyser.frequencyBinCount);

      source.connect(analyser);
      analyser.fftSize = 256;

      mediaRecorderRef.current.ondataavailable = (event) => {
        chunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: "audio/wav" });
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64Data = reader.result.split(",")[1];
          const savedAudioData = localStorage.getItem("audioData") || "[]";
          const audioData = JSON.parse(savedAudioData);
          audioData.push(base64Data);
          localStorage.setItem("audioData", JSON.stringify(audioData));
          setIsAudioAvailable(true);
        };

        reader.readAsDataURL(blob);
        chunksRef.current = [];
        silenceStartRef.current = null; 
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setIsGifAnimated(true);

      const checkSilence = () => {
        if (!analyserRef.current) return;

        const dataArray = dataArrayRef.current;
        analyserRef.current.getByteFrequencyData(dataArray);

        const volume = Math.sqrt(dataArray.reduce((sum, value) => sum + value * value, 0) / dataArray.length);
        console.log(`Volume: ${volume}`); 

        if (volume < threshold) {
          if (!silenceStartRef.current) {
            silenceStartRef.current = Date.now();
          } else if (Date.now() - silenceStartRef.current >= silenceTimeout) {
            stopRecording();
            return; 
          }
        } else {
          silenceStartRef.current = null;
        }

        requestAnimationFrame(checkSilence);
      };

      checkSilence();
    } catch (error) {
      console.error("Error accessing media devices:", error);
      alert("Could not access the microphone. Please ensure it is connected and permissions are granted.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsGifAnimated(false);
      if (silenceStartRef.current) {
        silenceStartRef.current = null;
      }
    }
  };

  const combineAndDownload = async () => {
    const savedAudioData = localStorage.getItem("audioData");
    if (savedAudioData) {
      try {
        const audioData = JSON.parse(savedAudioData);

        const fetchBlobs = audioData.map(async (data) => {
          const response = await fetch(`data:audio/wav;base64,${data}`);
          const arrayBuffer = await response.arrayBuffer();
          return arrayBuffer;
        });

        const audioBuffers = await Promise.all(fetchBlobs);
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        const decodedBuffers = await Promise.all(
          audioBuffers.map((buffer) => audioContext.decodeAudioData(buffer))
        );

        const totalLength = decodedBuffers.reduce(
          (sum, buffer) => sum + buffer.length,
          0
        );
        const combinedBuffer = audioContext.createBuffer(
          1,
          totalLength,
          audioContext.sampleRate
        );

        let offset = 0;
        decodedBuffers.forEach((buffer) => {
          combinedBuffer.copyToChannel(buffer.getChannelData(0), 0, offset);
          offset += buffer.length;
        });

        const wavBlob = audioBufferToWav(combinedBuffer);
        const formData = new FormData();
        formData.append("file", wavBlob, "combined-recording.wav");
        formData.append("email", "your-email@example.com");
        formData.append("hf_token", "your-hf-token");
        const response = await fetch("https://interview.dmlabs.in/upload", {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Failed to send audio data to the server");
        }

        const combinedUrl = URL.createObjectURL(wavBlob);

        const link = document.createElement("a");
        link.href = combinedUrl;
        link.download = "combined-recording.wav";
        link.click();
        URL.revokeObjectURL(combinedUrl);
        localStorage.removeItem("audioData");
        setIsAudioAvailable(false);
      } catch (error) {
        console.error("Error combining and sending audio:", error);
        alert("An error occurred while processing the audio.");
      }
    } else {
      alert("No recordings available to combine.");
    }
  };

  const audioBufferToWav = (buffer) => {
    const numChannels = buffer.numberOfChannels;
    const length = buffer.length * numChannels * 2 + 44;
    const wav = new Uint8Array(length);
    const view = new DataView(wav.buffer);

    writeString(view, 0, "RIFF");
    view.setUint32(4, length - 8, true);
    writeString(view, 8, "WAVE");

    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, buffer.sampleRate, true);
    view.setUint32(28, buffer.sampleRate * 2 * numChannels, true);
    view.setUint16(32, numChannels * 2, true);
    view.setUint16(34, 16, true);

    writeString(view, 36, "data");
    view.setUint32(40, length - 44, true);

    let offset = 44;
    for (let channel = 0; channel < numChannels; channel++) {
      const channelData = buffer.getChannelData(channel);
      for (let i = 0; i < channelData.length; i++) {
        view.setInt16(offset, channelData[i] * 0x7fff, true);
        offset += 2;
      }
    }

    return new Blob([wav], { type: "audio/wav" });
  };

  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  return (
    <div>
      <div>
        <img
          src={isGifAnimated ? "assets/sound.gif" : "assets/sound.png"}
          alt="Recording Indicator"
        />
        <button onClick={isRecording ? stopRecording : startRecording}>
          {isRecording ? "Stop Recording" : "Start Recording"}
        </button>
      </div>

      {isAudioAvailable && (
        <button onClick={combineAndDownload}>
          Download Combined Recording
        </button>
      )}
    </div>
  );
};

export default Voice;
